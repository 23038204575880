<template>
  <div id="page-selects">
    <!-- <h1> {{$t('userMaintTitle')}}</h1> -->
    <v-dialog v-model="dialogShowImage" persistent width="230">
      <v-card>
        <v-btn color="pink" dark small absolute right fab @click.native="hideToggleLockJobNumber">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text>
          <v-avatar
          :tile="true"
          :size="200"
          class="grey lighten-4"
          >
            <img :src="utils.imageProfile(selectedImage)" alt="" width="300" height="300">
          </v-avatar>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-container grid-list-xl fluid class="pa-2">
      <!-- <v-layout row wrap>
        <v-flex lg12 xs12>
          <v-widget v-bind:title="$t('user.userMaintTitle')">
            <div slot="widget-content"> -->
      <v-data-table
        v-model="vDataTable.selected"
        :headers="headersUser"
        :items="vDataTable.data"
        :search="vDataTable.search"
        fixed-header
        :page.sync="vDataTable.options.page"
        :itemsPerPage.sync="vDataTable.options.itemsPerPage"
        :options.sync="vDataTable.options"
        :height="$vuetify.breakpoint.height - 220"
        :footer-props="{
          itemsPerPageText: $t('itemsPerPageText'),
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          showFirstLastPage: true,
          showCurrentPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }"
        item-key="group_skey"
        class="elevation-1">
        <template v-slot:top>
          <v-row>
            <v-col cols="12" sm="12">
            <v-toolbar flat>
              <v-toolbar-title>User Group</v-toolbar-title>
            </v-toolbar>
            </v-col>
            <v-col cols="12" sm="12">
              <v-toolbar flat>
                <v-dialog v-model="dialog.dialogAddEditWindow" max-width="800px" eager>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined color="primary" v-bind="attrs" v-on="on" style="text-transform: capitalize">
                      <img src="../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>Add Group
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="10">
                            <v-text-field outlined hide-details label="Group Name" v-model="vDataTable.editedItem.group_name"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field outlined hide-details label="Priority" v-model="vDataTable.editedItem.priority"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                        <v-btn outlined color="primary" @click="save">{{$t('save')}}</v-btn>
                        <v-btn outlined color="primary" @click="close">{{$t('cancel')}}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- <v-divider class="mx-1" inset vertical></v-divider>
                <v-btn class="text-right" outlined color="primary" style="text-transform: capitalize" @click="resetPassword">
                  <img src="../assets/SoftwareClinicIcon/woman-working-on-a-computer.jpg" height="30" weight="30"/>Reset password
                </v-btn> -->
                <v-spacer></v-spacer>
                <v-text-field
                  dense
                  v-model="vDataTable.search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details>
                </v-text-field>
              </v-toolbar>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon color="teal" small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon color="pink" small @click="deleteItemConfirm(item)">delete</v-icon>
        </template>
        <template v-slot:item.data-table-no="{ item, index }">
          {{index + 1}}
        </template>
        <template v-slot:no-data>
          <v-alert type="info" border="bottom" colored-border elevation="2">
            {{$t('noData')}}
          </v-alert>
        </template>
        <template v-slot:no-results>
          <v-alert type="warning" border="bottom" colored-border elevation="2">
            {{stringFormat($t('searchNotFound'),vDataTable.search)}}
          </v-alert>
        </template>
        <template v-slot:footer>
          <tr class="sticky-table-footer" style="position: absolute" >
              <td>Selected({{vDataTable.selected.length}})s</td> 
          </tr>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog.dialogProgress" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            <span class="font-weight-bold dark white--text">{{progressMessage}}</span>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
            <!-- </div>
          </v-widget>
        </v-flex>
      </v-layout> -->
    </v-container>
  </div>
</template>

<script>
import Base64Binary from 'base64-arraybuffer'
// import { imageProfile } from '../utils'

export default {
  components: {
  },

  props: ['parentComponent'],

  data () {
    return {
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      dialog: {
        dialogProgress: false,
        dialogAddEditWindow: false,
      },
      showMenu: false,
      x: 0,
      y: 0,
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          group_skey: '',
          group_name: '',
          priority: '',
        },
        defaultItem: {
          group_skey: '',
          group_name: '',
          priority: '',
        },
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
        },
      },
      progressMessage: '',
      appSkey: -1,
      e3: true,
      dialogShowImage: false,
      selectedImage: null,
      dialogAddEditWindow: false,
      dialogProgress: false,
      searchUser: '',
      paginationUser: {
        sortBy: 'group_skey',
        rowsPerPage: 10
      },
      selectedUser: [],
      dataUser: [],
      editedIndex: -1,
      editedItem: {
        group_skey: '',
        group_name: '',
        priority: '',
      },
      deletedIndex: -1,
      deletedItem: {
        group_skey: '',
        group_name: '',
        priority: '',
      },
      defaultItem: {
        group_skey: '',
        group_name: '',
        priority: '',
      },
      dropDownData: [],
    }
  },

  computed: {
    formTitle () {
      return this.vDataTable.editedIndex === -1 ? 'New Group' : 'Edit Group'
    },
    headersUser () {
      return [
        { text: '#', align: 'center', value: 'data-table-no', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('actions'), sortable: false, value: 'actions', class: 'text-xl-left body-1 black--text' },
        { text: 'Group Name', value: 'group_name', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'Priority', value: 'priority', align: 'left', class: 'text-xl-left body-1 black--text' },
      ]
    }
  },

  watch: {
    'dialog.dialogAddEditWindow' (val) {
      val || this.close()
    }
  },

  created: function () {
    this.retrieveUserGroup()
    this.retrieveDropDown()
  },

  mounted () {
  },

  updated () {
  },

  methods: {
    selectLanguage (language) {
      this.vDataTable.data = []
    },
    initialize () {
      this.vDataTable.selected = []
      this.vDataTable.editedIndex = -1
      this.vDataTable.editedItem = {
        group_skey: '',
        group_name: '',
        priority: '',
      }
      this.vDataTable.defaultItem = {
        group_skey: '',
        group_name: '',
        priority: '',
      }
    },
    showToggleLockJobNumber (picture) {
      this.dialogShowImage = true
      this.selectedImage = picture
    },
    hideToggleLockJobNumber () {
      this.dialogShowImage = false
    },
    async retrieveUserGroup () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/user/retrieveUserGroup' + process.env.VUE_APP_DATABASE_TYPE)
        .then(response => {
          if (response.data.isSuccess) {
            this.dialog.dialogProgress = false
            this.vDataTable.data = response.data.data
          } else {
            this.dialog.dialogProgress = false
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.dialog.dialogProgress = false
          this.$swal({type: 'error', title: e.message})
        })
    },
    retrieveDropDown () {
      this.axios.post(process.env.VUE_APP_API + '/user/retrieveDropDown' + process.env.VUE_APP_DATABASE_TYPE, {
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.dropDownData = response.data.data[0]
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async addUserGroup (groupName, priority) {
      await this.axios.post(process.env.VUE_APP_API + '/user/addUserGroup' + process.env.VUE_APP_DATABASE_TYPE, {
        group_name: groupName,
        priority: priority,
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.data.push(this.vDataTable.editedItem)
            this.retrieveUserGroup()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async updateUserGroup (groupSkey, groupName, priority) {
      await this.axios.post(process.env.VUE_APP_API + '/user/updateUserGroup' + process.env.VUE_APP_DATABASE_TYPE, {
        group_skey: groupSkey,
        group_name: groupName,
        priority: priority,
      })
        .then(response => {
          if (response.data.isSuccess) {
            Object.assign(this.vDataTable.data[this.vDataTable.editedIndex], this.vDataTable.editedItem)
            this.retrieveUserGroup()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    deleteUserGroup (item) {
      var groupSkey = this.deletedItem.group_skey
      this.dataUser.splice(this.deletedIndex, 1)
      groupSkey = item.group_skey
      this.axios.post(process.env.VUE_APP_API + '/user/deleteUserGroup' + process.env.VUE_APP_DATABASE_TYPE, {
        group_skey: groupSkey
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.retrieveUserGroup()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    toggleAll () {
      if (this.selectedUser.length) this.selectedUser = []
      else this.selectedUser = this.dataUser.slice()
    },
    changeSort (column) {
      if (this.paginationUser.sortBy === column) {
        this.paginationUser.descending = !this.paginationUser.descending
      } else {
        this.paginationUser.sortBy = column
        this.paginationUser.descending = false
      }
    },
    /* editItem (item) {
      this.editedIndex = this.dataUser.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogAddEditWindow = true
    }, */
    editItem (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.dialog.dialogAddEditWindow = true
    },
    async deleteItemConfirm (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.$swal({
        text: 'คุณต้องการที่จะลบข้อมูล Group ?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then(async (result) => {
        if (result) {
          var val = await this.deleteUserGroup(this.vDataTable.editedItem)
          if (val !== undefined) {
            this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(this.vDataTable.editedItem), 1)
          }
          this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
          this.vDataTable.editedIndex = -1
        }
      })
    },
    close () {
      this.dialog.dialogAddEditWindow = false
      this.$nextTick(() => {
        this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
        this.vDataTable.editedIndex = -1
      })
    },
    async save () {
      if (this.validateReg() === true) {
        if (this.vDataTable.editedIndex > -1) {
          await this.updateUserGroup(this.vDataTable.editedItem.group_skey,
            this.vDataTable.editedItem.group_name,
            this.vDataTable.editedItem.priority)
        } else {
          await this.addUserGroup(this.vDataTable.editedItem.group_name,
            this.vDataTable.editedItem.priority)
        }
        this.close()
      }
    },
    validateReg () {
      var messageCustom = ''
      if (!this.vDataTable.editedItem.group_name) {
        /* messageCustom = messageCustom + his.$t('EgatWebEmployee.messageEmployeeID') + '<br />' */
        messageCustom = messageCustom + 'Please Enter Group Name' + '<br />'
      }
      if (!this.vDataTable.editedItem.priority) {
        /* messageCustom = messageCustom + his.$t('EgatWebEmployee.messageEmployeeID') + '<br />' */
        messageCustom = messageCustom + 'Please Enter Priority' + '<br />'
      }
      if (messageCustom) {
        this.$swal({type: 'error', html: messageCustom})
        return false
      } else {
        return true
      }
    },
    resetPassword () {
      this.$swal({
        text: 'Reset Password',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then((result) => {
        if (result) {
          this.dialog.dialogProgress = true
          this.progressMessage = this.$t('waiting')
          this.axios.post(process.env.VUE_APP_API + '/user/resetPassword' + process.env.VUE_APP_DATABASE_TYPE)
            .then(response => {
              if (response.data.isSuccess) {
                this.dialog.dialogProgress = false
                this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
              } else {
                this.dialog.dialogProgress = false
                this.$swal({type: 'error', title: response.data.reasonText})
              }
            })
            .catch(e => {
              this.dialog.dialogProgress = false
              this.$swal({type: 'error', title: e.message})
            })
        }
      })
    }
  }
}
</script>

<style lang="css">
.dialog.centered-dialog,
.v-dialog.centered-dialog
{
  background: #282c2dad;
  box-shadow: none;
  border-radius: 6px;
  width: auto;
  color: whitesmoke;
},
.DataTable
{
    display: none;
    border: none;
},
.rounded-card
{
  display: none;
  border: none;
}
</style>